import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme, GlobalStyle } from "./styles/theme";
import { useTheme } from "./shared/hooks/useTheme";
import Layout from "./shared/components/Layout";
import LoadingSpinner from "./shared/components/LoadingSpinner";

const Home = lazy(() => import("./pages/home/Home"));
const About = lazy(() => import("./pages/about/About"));
const Experience = lazy(() => import("./pages/experience/Experience"));
const Education = lazy(() => import("./pages/education/Education"));
const Portfolio = lazy(() => import("./pages/portfolio/Portfolio"));
const Publications = lazy(() => import("./pages/publications/Publications"));

const queryClient = new QueryClient();

const App: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  const [background, setBackground] = useState<
    "off" | "gradient" | "particles"
  >("particles");

  const handleBackgroundChange = (
    newBackground: "off" | "gradient" | "particles"
  ) => {
    setBackground(newBackground);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <GlobalStyle />
        <Router>
          <Layout
            theme={theme}
            toggleTheme={toggleTheme}
            currentBackground={background}
            onBackgroundChange={handleBackgroundChange}
          >
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/experience" element={<Experience />} />
                <Route path="/education" element={<Education />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/publications" element={<Publications />} />
              </Routes>
            </Suspense>
          </Layout>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;