// src/components/ThemeSwitch.tsx
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const SwitchContainer = styled(motion.button)`
  background: none;
  cursor: pointer;
  width: 60px;
  height: 30px;
  border-radius: 15px;
  padding: 3px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  }
`;

const Handle = styled(motion.div)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background.main};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

interface ThemeSwitchProps {
  theme: 'light' | 'dark';
  toggleTheme: () => void;
}

const ThemeSwitch: React.FC<ThemeSwitchProps> = ({ theme, toggleTheme }) => {
  const isLight = theme === 'light';
  const backgroundColor = isLight ? '#f0c420' : '#2c3e50';
  const iconColor = isLight ? '#2c3e50' : '#f0c420';
  const borderColor = isLight ? '#d4ab1d' : '#1e2a3a';

  return (
    <SwitchContainer 
      onClick={toggleTheme}
      animate={{ 
        backgroundColor,
        borderColor,
      }}
      transition={{ duration: 0.3 }}
    >
      <Handle
        animate={{ 
          x: isLight ? 0 : 30,
          rotate: isLight ? 0 : 360,
        }}
        transition={{ 
          type: 'spring', 
          stiffness: 300, 
          damping: 20,
          rotate: { duration: 0.5 }
        }}
      >
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width={isLight ? "20" : "26"}  
          height={isLight ? "20" : "26"}
          viewBox="0 0 24 24"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          animate={{
            scale: isLight ? 1 : 0.8,
            opacity: 1,
          }}
          transition={{ duration: 0.3 }}
        >
          {isLight ? (
            <>
              <circle cx="12" cy="12" r="5" stroke={iconColor} />
              <line x1="12" y1="1" x2="12" y2="3" stroke={iconColor} />
              <line x1="12" y1="21" x2="12" y2="23" stroke={iconColor} />
              <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" stroke={iconColor} />
              <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" stroke={iconColor} />
              <line x1="1" y1="12" x2="3" y2="12" stroke={iconColor} />
              <line x1="21" y1="12" x2="23" y2="12" stroke={iconColor} />
              <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" stroke={iconColor} />
              <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" stroke={iconColor} />
            </>
          ) : (
            <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" stroke={iconColor} />
          )}
        </motion.svg>
      </Handle>
    </SwitchContainer>
  );
};

export default ThemeSwitch;