import { DefaultTheme, createGlobalStyle } from "styled-components";
import styled from "styled-components";

const baseTheme = {
  fonts: {
    main: "'Roboto', sans-serif",
    heading: "'Montserrat', sans-serif",
  },
  breakpoints: {
    mobile: "576px",
    tablet: "768px",
    desktop: "1024px",
  },
  transition: {
    default: "0.3s ease-in-out",
    color: "0.3s ease-in-out",
    background: "0.3s ease-in-out",
  },
};

export const lightTheme: DefaultTheme = {
  ...baseTheme,
  colors: {
    primary: {
      main: "#3498db",
      light: "#5dade2",
      dark: "#2980b9",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#2ecc71",
      light: "#55e792",
      dark: "#27ae60",
      contrastText: "#ffffff",
    },
    background: {
      default: "#f9f9f9",
      paper: "#ffffff",
      paperRGB: "255, 255, 255",
      alt: "#f0f0f0",
      contrast: "#2c2c2c",
      contrastAlt: "#333333",
      secondary: "#f0f0f0",
    },
    text: {
      primary: "#333333",
      secondary: "#666666",
      disabled: "#999999",
    },
    accent: {
      main: "#e74c3c",
      light: "#ed7669",
      dark: "#c0392b",
    },
    success: {
      main: "#27ae60",
      light: "#2ecc71",
      dark: "#219653",
    },
    warning: {
      main: "#f39c12",
      light: "#f7bc5d",
      dark: "#d35400",
    },
    error: {
      main: "#e74c3c",
      light: "#ed7669",
      dark: "#c0392b",
    },
    info: {
      main: "#3498db",
      light: "#5dade2",
      dark: "#2980b9",
      primary: "#3498db",
      secondary: "#2980b9",
      tertiary: "#a9cce3",
    },
    divider: "rgba(0, 0, 0, 0.12)",
  },
  shadow: {
    default: "0 2px 4px rgba(0, 0, 0, 0.2)",
    hover: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
};

export const darkTheme: DefaultTheme = {
  ...baseTheme,
  colors: {
    primary: {
      main: "#3498db",
      light: "#5dade2",
      dark: "#2980b9",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#2ecc71",
      light: "#55e792",
      dark: "#27ae60",
      contrastText: "#ffffff",
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
      paperRGB: "30, 30, 30",
      alt: "#2c2c2c",
      contrast: "#f0f0f0",
      contrastAlt: "#e0e0e0",
      secondary: "#333333",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b3b3b3",
      disabled: "#666666",
    },
    accent: {
      main: "#e74c3c",
      light: "#ed7669",
      dark: "#c0392b",
    },
    success: {
      main: "#27ae60",
      light: "#2ecc71",
      dark: "#219653",
    },
    warning: {
      main: "#f39c12",
      light: "#f7bc5d",
      dark: "#d35400",
    },
    error: {
      main: "#e74c3c",
      light: "#ed7669",
      dark: "#c0392b",
    },
    info: {
      main: "#3498db",
      light: "#5dade2",
      dark: "#2980b9",
      primary: "#2980b9",
      secondary: "#3498db",
      tertiary: "#2980b9",
    },
    divider: "rgba(255, 255, 255, 0.12)",
  },
  shadow: {
    default: "0 2px 4px rgba(255, 255, 255, 0.2)",
    hover: "0 4px 8px rgba(255, 255, 255, 0.2)",
  },
};

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts.main};
    background-color: ${({ theme }) => theme.colors.background.default};
    color: ${({ theme }) => theme.colors.text.primary};
    transition: background-color 0.3s ease, color 0.5s ease;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.fonts.heading};
    color: ${({ theme }) => theme.colors.text.primary};
    transition: color 0.5s ease;
  }

  a {
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: none;
    transition: color 0.5s ease;
    
    &:hover {
      text-decoration: underline;
    }
  }

  * {
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
`;

export const ExternalLinkButton = styled.a`
  color: ${(props) => props.theme.text};
  text-decoration: none;
  margin: 0 0px;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-left: 5px;
  }
`;
