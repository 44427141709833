import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const SwitchContainer = styled(motion.button)`
  background: none;
  cursor: pointer;
  width: 90px; // Adjusted width for three options
  height: 30px;
  border-radius: 15px;
  padding: 3px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  }
`;

const Handle = styled(motion.div)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background.main};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const backgroundColors = {
  off: "#6c757d",
  gradient: "#ff7f50",
  particles: "#4b0082",
};

const BackgroundIcon: React.FC<{ type: string; color: string }> = ({
  type,
  color,
}) => {
  switch (type) {
    case "off":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      );
    case "gradient":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M6 3v12"></path>
          <path d="M18 9a3 3 0 0 0-3 3v10"></path>
          <path d="M6 15a3 3 0 0 0 3 3h9"></path>
        </svg>
      );
    case "particles":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill={color}
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="2" />
          <circle cx="6" cy="6" r="1.5" />
          <circle cx="18" cy="18" r="1.5" />
          <circle cx="18" cy="6" r="1.5" />
          <circle cx="6" cy="18" r="1.5" />
          <circle cx="3" cy="12" r="1" />
          <circle cx="21" cy="12" r="1" />
          <circle cx="12" cy="3" r="1" />
          <circle cx="12" cy="21" r="1" />
        </svg>
      );
    default:
      return null;
  }
};

interface BackgroundSwitchProps {
  currentBackground: "off" | "gradient" | "particles";
  onBackgroundChange: (background: "off" | "gradient" | "particles") => void;
}

const BackgroundSwitch: React.FC<BackgroundSwitchProps> = ({
  currentBackground,
  onBackgroundChange,
}) => {
  const backgroundOptions = ["off", "gradient", "particles"];
  const currentIndex = backgroundOptions.indexOf(currentBackground);

  const handleClick = () => {
    const nextIndex = (currentIndex + 1) % backgroundOptions.length;
    onBackgroundChange(
      backgroundOptions[nextIndex] as "off" | "gradient" | "particles"
    );
  };

  const backgroundColor = backgroundColors[currentBackground];
  const iconColor = "#ffffff";

  return (
    <SwitchContainer
      onClick={handleClick}
      animate={{
        backgroundColor,
        borderColor: backgroundColor,
      }}
      transition={{ duration: 0.3 }}
    >
      <Handle
        animate={{
          x: currentIndex * 30,
          rotate: currentIndex * 120, // 360 degrees / 3 options = 120 degrees per option
        }}
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 20,
          rotate: { duration: 0.5 },
        }}
      >
        <motion.div
          animate={{
            scale: 1,
            opacity: 1,
          }}
          transition={{ duration: 0.3 }}
        >
          <BackgroundIcon type={currentBackground} color={iconColor} />
        </motion.div>
      </Handle>
    </SwitchContainer>
  );
};

export default BackgroundSwitch;
