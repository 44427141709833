import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ThemeSwitch from "./ThemeSwitch";
import SettingsMenu from "./SettingsMenu";
import { ExternalLink } from "lucide-react";
import { ExternalLinkButton } from "../../styles/theme";

const HeaderWrapper = styled.header`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: bold;

  &:hover {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

interface HeaderProps {
  theme: "light" | "dark";
  toggleTheme: () => void;
  currentBackground: "off" | "gradient" | "particles";
  onBackgroundChange: (background: "off" | "gradient" | "particles") => void;
}

const Header: React.FC<HeaderProps> = ({
  theme,
  toggleTheme,
  currentBackground,
  onBackgroundChange,
}) => {
  return (
    <HeaderWrapper>
      <Nav>
        <NavLinks>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/experience">Experience</NavLink>
          <NavLink to="/education">Education</NavLink>
          <NavLink to="/portfolio">Portfolio</NavLink>
          <NavLink to="/publications">Publications</NavLink>
          {/* <ExternalLinkButton
            href="https://code-explorer.jakegonzalez.xyz"
            target="_blank"
            rel="noopener noreferrer"
          >
            Code <ExternalLink size={14} />
          </ExternalLinkButton> */}
        </NavLinks>
        <RightSection>
          <SettingsMenu
            currentBackground={currentBackground}
            onBackgroundChange={onBackgroundChange}
            theme={theme}
            toggleTheme={toggleTheme}
          />
        </RightSection>
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;
