import React, { useState } from "react";
import styled from "styled-components";
import { Moon, Settings, Sun, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import ThemeSwitch from "./ThemeSwitch";
import BackgroundSwitch from "./BackgroundSwitch";

const SettingsButton = styled.button`
  // position: fixed;
  // top: 1rem;
  // right: 1rem;
  // z-index: 50;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.primary};
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const MenuContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 20rem;
  background-color: ${({ theme }) =>
    `rgba(${theme.colors.background.paperRGB}, 0.5)`};
  backdrop-filter: blur(10px);
  box-shadow: ${({ theme }) => theme.shadow.default};
  z-index: 40;
  padding: 1.5rem;
  overflow-y: auto;
`;

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const MenuTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text.primary};
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const SettingSection = styled.div`
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 0.5rem;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
`;

const RadioInput = styled.input`
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 50%;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: ${({ theme }) => theme.colors.primary.main};
    border: 2px solid ${({ theme }) => theme.colors.primary.main};
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.colors.background.paper};
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

const SwitchLabel = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
`;

interface SettingsMenuProps {
  currentBackground: "off" | "gradient" | "particles";
  onBackgroundChange: (background: "off" | "gradient" | "particles") => void;
  theme: "light" | "dark";
  toggleTheme: () => void;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({
  currentBackground = "particles", // Provide a default value
  onBackgroundChange,
  theme,
  toggleTheme,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <>
      <SettingsButton onClick={toggleMenu} aria-label="Toggle Settings Menu">
        <Settings size={24} />
      </SettingsButton>
      <AnimatePresence>
        {isOpen && (
          <MenuContainer
            initial={{ opacity: 0, x: 300 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 300 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <MenuHeader>
              <MenuTitle>Settings</MenuTitle>
              <SettingsButton
                onClick={toggleMenu}
                aria-label="Close Settings Menu"
              >
                <X size={24} />
              </SettingsButton>
            </MenuHeader>
            <SettingSection>
              <SectionTitle>Theme</SectionTitle>
              <SwitchContainer>
                <SwitchLabel>
                  {theme === "light" ? "Light" : "Dark"} Mode
                </SwitchLabel>
                <ThemeSwitch theme={theme} toggleTheme={toggleTheme} />
              </SwitchContainer>
            </SettingSection>
            <SettingSection>
              <SectionTitle>Background</SectionTitle>
              <SwitchContainer>
                <SwitchLabel>
                  {currentBackground.charAt(0).toUpperCase() +
                    currentBackground.slice(1)}
                </SwitchLabel>
                <BackgroundSwitch
                  currentBackground={currentBackground}
                  onBackgroundChange={onBackgroundChange}
                />
              </SwitchContainer>
            </SettingSection>
            {/* Add more setting sections here */}
          </MenuContainer>
        )}
      </AnimatePresence>
    </>
  );
};

export default SettingsMenu;
