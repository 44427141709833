import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import AnimatedBackground from "./AnimatedBackground";

const Main = styled.main`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  z-index: 1;
`;

const ContentWrapper = styled.div`
  position: relative;
  min-height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
`;

interface LayoutProps {
  children: React.ReactNode;
  theme: "light" | "dark";
  toggleTheme: () => void;
  currentBackground: "off" | "gradient" | "particles";
  onBackgroundChange: (background: "off" | "gradient" | "particles") => void;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  theme,
  toggleTheme,
  currentBackground,
  onBackgroundChange,
}) => {
  return (
    <ContentWrapper>
      {currentBackground !== "off" && (
        <AnimatedBackground type={currentBackground} />
      )}
      <Header
        theme={theme}
        toggleTheme={toggleTheme}
        currentBackground={currentBackground}
        onBackgroundChange={onBackgroundChange}
      />
      <Main>{children}</Main>
      <Footer />
    </ContentWrapper>
  );
};

export default Layout;
