import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  color: ${({ theme }) => theme.colors.text.disabled};
  text-align: center;
  padding: 1rem;
  margin-top: 2rem;
`;

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <p>
        &copy; {new Date().getFullYear()} Jake Gonzalez. All rights reserved.
      </p>
    </FooterWrapper>
  );
};

export default Footer;
