// import { useState, useEffect } from "react";

// const useTheme = () => {
//   const [theme, setTheme] = useState("light");

//   const toggleTheme = () => {
//     if (theme === "light") {
//       window.localStorage.setItem("theme", "dark");
//       setTheme("dark");
//     } else {
//       window.localStorage.setItem("theme", "light");
//       setTheme("light");
//     }
//   };

//   useEffect(() => {
//     const localTheme = window.localStorage.getItem("theme");
//     if (localTheme) {
//       setTheme(localTheme);
//     }
//   }, []);

//   return { theme, toggleTheme };
// };

// export default useTheme;
import { useState, useEffect } from "react";

export const useTheme = () => {
  const [theme, setTheme] = useState<"light" | "dark">("light");

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") as "light" | "dark" | null;
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return { theme, toggleTheme };
};
