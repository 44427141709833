import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
`;

// Gradient Shift Background
const gradientShift = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const GradientBackground = styled(BackgroundWrapper)`
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  opacity: 0.25;
  animation: ${gradientShift} 15s ease infinite;
`;

// Particle Background
const Particle = styled.div`
  position: absolute;
  background-color: ${props => props.theme.colors.background.contrast};
  opacity: 0.125;
  border-radius: 50%;
`;

interface ParticleType {
  x: number;
  y: number;
  size: number;
  speedX: number;
  speedY: number;
}

const ParticleBackground = () => {
  const [particles, setParticles] = useState<ParticleType[]>([]);

  useEffect(() => {
    const newParticles: ParticleType[] = Array.from({ length: 50 }, () => ({
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      size: Math.random() * 5 + 1,
      speedX: Math.random() * 3 - 1.5,
      speedY: Math.random() * 3 - 1.5,
    }));
    setParticles(newParticles);

    const animateParticles = () => {
      setParticles(prevParticles =>
        prevParticles.map(particle => ({
          ...particle,
          x: (particle.x + particle.speedX + window.innerWidth) % window.innerWidth,
          y: (particle.y + particle.speedY + window.innerHeight) % window.innerHeight,
        }))
      );
      requestAnimationFrame(animateParticles);
    };

    const animationId = requestAnimationFrame(animateParticles);
    return () => cancelAnimationFrame(animationId);
  }, []);

  return (
    <BackgroundWrapper>
      {particles.map((particle, index) => (
        <Particle
          key={index}
          style={{
            left: `${particle.x}px`,
            top: `${particle.y}px`,
            width: `${particle.size}px`,
            height: `${particle.size}px`,
          }}
        />
      ))}
    </BackgroundWrapper>
  );
};

// Game of Life Background
interface CellProps {
  alive: boolean;
}

const Cell = styled.div<CellProps>`
  width: 10px;
  height: 10px;
  background-color: ${props => props.alive 
    ? props.theme.colors.primary.main 
    : 'transparent'};
  opacity: ${props => props.alive ? 0.7 : 0};
  transition: all 0.3s ease;
  border-radius: 2px;
`;

const GameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 10px);
  gap: 1px;
  width: 100%;
  height: 100%;
`;

const GameOfLifeBackground: React.FC = () => {
  const rows = Math.ceil(window.innerHeight / 11); // 10px cell + 1px gap
  const cols = Math.ceil(window.innerWidth / 11);

  const [grid, setGrid] = useState(() =>
    Array.from({ length: rows }, () =>
      Array.from({ length: cols }, () => false)
    )
  );

  const [isRunning, setIsRunning] = useState(false);

  const runSimulation = useCallback(() => {
    if (!isRunning) return;

    setGrid(g => {
      return g.map((row, i) =>
        row.map((cell, j) => {
          const neighbors = [
            [-1, -1], [-1, 0], [-1, 1],
            [0, -1], [0, 1],
            [1, -1], [1, 0], [1, 1]
          ].reduce((acc, [x, y]) => {
            const newI = (i + x + rows) % rows;
            const newJ = (j + y + cols) % cols;
            return acc + (g[newI][newJ] ? 1 : 0);
          }, 0);

          return (cell && neighbors === 2) || neighbors === 3;
        })
      );
    });
  }, [isRunning, rows, cols]);

  useEffect(() => {
    const intervalId = setInterval(runSimulation, 200);
    return () => clearInterval(intervalId);
  }, [runSimulation]);

  const handleCellClick = (i: number, j: number) => {
    if (isRunning) return;

    const newGrid = grid.map((row, rowIndex) =>
      row.map((cell, colIndex) =>
        rowIndex === i && colIndex === j ? !cell : cell
      )
    );
    setGrid(newGrid);
  };

  const handleStartStop = () => {
    setIsRunning(!isRunning);
  };

  const handleClear = () => {
    setIsRunning(false);
    setGrid(Array.from({ length: rows }, () =>
      Array.from({ length: cols }, () => false)
    ));
  };

  return (
    <BackgroundWrapper>
      <GameGrid>
        {grid.map((row, i) =>
          row.map((cell, j) => (
            <Cell
              key={`${i}-${j}`}
              alive={cell}
              onClick={() => handleCellClick(i, j)}
            />
          ))
        )}
      </GameGrid>
      <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 10 }}>
        <button onClick={handleStartStop}>{isRunning ? 'Stop' : 'Start'}</button>
        <button onClick={handleClear}>Clear</button>
      </div>
    </BackgroundWrapper>
  );
};
interface AnimatedBackgroundsProps {
  type: 'gradient' | 'particles' | 'gameOfLife';
}

const AnimatedBackgrounds: React.FC<AnimatedBackgroundsProps> = ({ type }) => {
  switch (type) {
    case 'gradient':
      return <GradientBackground />;
    case 'particles':
      return <ParticleBackground />;
    case 'gameOfLife':
      return <GameOfLifeBackground />;
    default:
      return null;
  }
};

export default AnimatedBackgrounds;